<template>
  <div class="col for_tasks_editing_page">
    <div class="wrapper_for_sharing_tasks">
      <div class="my-4" style="margin-left: -15px; width: calc(100% + 30px)">
        <!-- //? start Score Cards  -->
        <!-- Medium & Small Screens  -->
        <div class="my-4  hidden_when_print_sm_screen">
          <div class="col-sm-12 d-flex justify-content-center flex-column">
            <!-- Sequence  -->
            <div class="d-flex align-items-center">
              <p class="label sequence main-label">
                {{ userScore.sequence != null ? userScore.sequence : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("Sequence")
              }}</span>
            </div>
            <div class="d-flex align-items-center">
              <p class="label precision main-label">
                {{ userScore.precision != null ? userScore.precision : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("precision")
              }}</span>
            </div>
            <!-- Technical Reasoning  -->
            <div class="d-flex align-items-center">
              <p class="label technical_reasoning main-label">
                {{
                  userScore.technical_reasoning != null
                    ? userScore.technical_reasoning
                    : 0
                }}
              </p>
              <span style="font-size: 1.3rem">{{
                localization("Technical Reasoning")
              }}</span>
            </div>
            <!-- Confluence  -->
            <div class="d-flex align-items-center">
              <p class="label confluence main-label">
                {{ userScore.confluence != null ? userScore.confluence : 0 }}
              </p>
              <span style="font-size: 1.3rem; text-transform: capitalize">{{
                localization("Confluence")
              }}</span>
            </div>
          </div>
        </div>

        <!-- //! Large Screens  d-lg-block-->
        <div class="my-4 d-none ">
          <div class="col-sm-12 px-0 px-lg-3 d-flex align-items-center">
            <!-- Sequence  -->
            <card
              :height="`${userScore.sequence * 2.5 + 120}px`"
              style="
                background-color: #39c;
                border-radius: 0;
                border: 1px solid #ccc;
                width: 100%;
                max-height: 230px;
                min-height: 120px;
              "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->

                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("Sequence") }}
                </p>

                <div
                  class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Sequence shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Small & medium Screens  -->

                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.sequence / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{ userScore.sequence != null ? userScore.sequence : 0 }}
                  </p>
                </div>
              </div>
            </card>
            <!-- Precision   -->
            <card
              :height="`${userScore.precision * 2.5 + 120}px`"
              style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #3c3;
                max-height: 230px;
                min-height: 120px;
              "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->

                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("precision") }}
                </p>

                <div
                  class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("precision shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.precision / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{ userScore.precision != null ? userScore.precision : 0 }}
                  </p>
                </div>
              </div>
            </card>
            <!-- Technical Reasoning  -->
            <card
              :height="`${userScore.technical_reasoning * 2.5 + 120}px`"
              style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #f93;
                max-height: 230px;
                min-height: 120px;
              "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->
                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 1.6rem"
                >
                  {{ localization("Technical Reasoning") }}
                </p>
                <div
                  class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                    flex-column flex-md-row
                  "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Technical Reasoning shortcut") }}
                  </p>

                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize:
                        (userScore.technical_reasoning / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{
                      userScore.technical_reasoning != null
                        ? userScore.technical_reasoning
                        : 0
                    }}
                  </p>
                </div>
              </div>
            </card>
            <!-- Confluence  -->
            <card
              :height="`${userScore.confluence * 2.5 + 120}px`"
              style="
                border-radius: 0;
                border: 1px solid #ccc;
                min-width: 25%;
                background-color: #c36;
                max-height: 230px;
                min-height: 120px;
              "
              class="my-auto score"
            >
              <div class="d-flex flex-column my-auto">
                <!-- score title text -->
                <!-- Medium & larger screens text   -->
                <p
                  class="mt-1 text-center text-white mb-0"
                  style="font-size: 2rem"
                >
                  {{ localization("Confluence") }}
                </p>

                <div
                  class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                  "
                >
                  <!-- First Letter  -->
                  <p class="letter mb-0 text-white">
                    {{ localization("Confluence shortcut") }}
                  </p>
                  <!-- Score Number  -->
                  <!-- Large Screens  -->
                  <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                  <p
                    class="number mb-0 text-white"
                    :style="{
                      fontSize: (userScore.confluence / 28) * 25 + 65 + 'px'
                    }"
                  >
                    {{
                      userScore.confluence != null ? userScore.confluence : 0
                    }}
                  </p>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
      <!-- // Start Large Screens  -->
      <div style="width: 100% " class="my-4 main_use_ur_pattern_container_lg">
        <card class="">
          <div class="main_auth_user_info">
            <div>
              <span class="d-block mb-2 n_name">{{
                `${user2.first_name} ${user2.last_name}`
              }}</span>
              <span class="d-block n_email"> {{ user2.email }}</span>
            </div>
            <div class="classification_classification">
              <span
                class="classification"
                v-if="user2.classification == 'Bridge'"
                >{{ localization("user type bridge") }}</span
              >
              <span
                class="classification"
                v-if="user2.classification == 'Dynamic'"
                >{{ localization("User type dynamic") }}</span
              >
              <span
                class="classification"
                v-if="user2.classification == 'Strong-Willed Learner'"
                >{{ localization("user type SWL") }}</span
              >
            </div>
          </div>
          <div class="which_user_type mt-2 mb-3">
            <div class="nn">
              <div class="another_content">
                <!-- <span class="tit text-uppercase"
                    >{{ user.classification }} definition</span
                  > -->
                <span
                  class="tit text-uppercase"
                  v-if="user2.classification == 'Bridge'"
                  >{{ localization("user type bridge") }}
                  {{ localization("definition") }}
                </span>
                <span
                  class="tit text-uppercase"
                  v-if="user2.classification == 'Dynamic'"
                  >{{ localization("User type dynamic") }}
                  {{ localization("definition") }}</span
                >
                <span
                  class="tit text-uppercase"
                  v-if="user2.classification == 'Strong-Willed Learner'"
                  >{{ localization("user type SWL") }}
                  {{ localization("definition") }}</span
                >
                <!-- Description Text  -->

                <span
                  class="tex d-block"
                  v-if="user2.classification == 'Bridge'"
                >
                  {{ localization("bridge tool tip") }}
                </span>
                <span
                  class="tex d-block"
                  v-if="user2.classification == 'Dynamic'"
                >
                  {{ localization("dynamic tool tip") }}
                </span>
                <span
                  class="tex d-block"
                  v-if="user2.classification == 'Strong-Willed Learner'"
                >
                  {{ localization("swl to tip") }}
                </span>
              </div>
              <!-- Dynamic -->
              <!-- Strong-Willed Learner -->
            </div>
          </div>
          <div class="row my-4  main-card-print">
            <div
              class="col-sm-12 px-0 px-lg-3 d-flex align-items-center the_patterns_with_new_theme"
            >
              <!-- Start Sequence  -->
              <div style="min-width: 25%;">
                <card
                  :height="`${userScore.sequence * 2.5 + 120}px`"
                  style="
              background-color: #39c;
              border-radius: 0;

              width: 100%;
              max-height: 230px;
              min-height: 120px;
            "
                  class="my-auto score"
                >
                  <div class="d-flex flex-column my-auto">
                    <!-- score title text -->
                    <!-- Medium & larger screens text   -->
                    <p
                      class="mt-1 text-center text-white mb-0"
                      style="font-size: 2rem"
                    >
                      {{ localization("Sequence") }}
                    </p>
                    <div
                      class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
                    >
                      <!-- First Letter  -->
                      <p class="letter mb-0 text-white">
                        {{ localization("Sequence shortcut") }}
                      </p>
                      <!-- Score Number  -->

                      <!-- Large Screens  -->
                      <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 50 is min font value -->
                      <p
                        class="number mb-0 text-white"
                        :style="{
                          fontSize: (userScore.sequence / 28) * 25 + 65 + 'px'
                        }"
                      >
                        {{
                          userScore.sequence != null ? userScore.sequence : 0
                        }}
                      </p>
                    </div>
                  </div>
                </card>
                <div class="new_theme_pattern_content">
                  <div class="main_content_container with_sequence">
                    <span class="d-block text-uppercase ti">{{
                      localization("SEQUENTIAL LEARNING PATTERNS")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Organizes life")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Makes Lists")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Lives by schedules")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Makes extensive plans")
                    }}</span>
                  </div>
                </div>
              </div>
              <!-- End Sequence  -->

              <!-- Start Precision   -->
              <div style="min-width: 25%;">
                <card
                  :height="`${userScore.precision * 2.5 + 120}px`"
                  style="
              border-radius: 0;

              min-width: 25%;
              background-color: #3c3;
              max-height: 230px;
              min-height: 120px;
            "
                  class="my-auto score"
                >
                  <div class="d-flex flex-column my-auto">
                    <!-- score title text -->

                    <!-- Medium & larger screens text   -->
                    <p
                      class="mt-1 text-center text-white mb-0"
                      style="font-size: 2rem"
                    >
                      {{ localization("precision") }}
                    </p>

                    <div
                      class="
                  d-flex
                  align-items-center align-items-xxl-start
                  justify-content-center justify-content-lg-around
                "
                    >
                      <!-- First Letter  -->
                      <p class="letter mb-0 text-white">
                        {{ localization("precision shortcut") }}
                      </p>
                      <!-- Score Number  -->
                      <!-- Large Screens  -->
                      <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                      <p
                        class="number mb-0 text-white"
                        :style="{
                          fontSize: (userScore.precision / 28) * 25 + 65 + 'px'
                        }"
                      >
                        {{
                          userScore.precision != null ? userScore.precision : 0
                        }}
                      </p>
                    </div>
                  </div>
                </card>
                <div class="new_theme_pattern_content">
                  <div class="main_content_container with_precision">
                    <span class="d-block text-uppercase ti">{{
                      localization("PRECISION LEARNING PATTERNS")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Relishes information")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Seeks facts")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Must be right")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Provides detailed responses")
                    }}</span>
                  </div>
                </div>
              </div>
              <!-- End Precision   -->
              <!-- Start Technical Reasoning  -->
              <div style="min-width: 25%;">
                <!-- border: 1px solid #ccc; -->
                <card
                  :height="`${userScore.technical_reasoning * 2.5 + 120}px`"
                  style="
                border-radius: 0;

                min-width: 25%;
                background-color: #f93;
                max-height: 230px;
                min-height: 120px;
              "
                  class="my-auto score"
                >
                  <div class="d-flex flex-column my-auto">
                    <!-- score title text -->
                    <!-- Medium & larger screens text   -->
                    <p
                      class="mt-1 text-center text-white mb-0"
                      style="font-size: 1.6rem"
                    >
                      {{ localization("Technical Reasoning") }}
                    </p>
                    <div
                      class="
                    d-flex
                    align-items-center align-items-xxl-start
                    justify-content-center justify-content-lg-around
                    flex-column flex-md-row
                  "
                    >
                      <!-- First Letter  -->
                      <p class="letter mb-0 text-white">
                        {{ localization("Technical Reasoning shortcut") }}
                      </p>
                      <!-- Score Number  -->
                      <!-- Large Screens  -->
                      <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                      <p
                        class="number mb-0 text-white"
                        :style="{
                          fontSize:
                            (userScore.technical_reasoning / 28) * 25 +
                            65 +
                            'px'
                        }"
                      >
                        {{
                          userScore.technical_reasoning != null
                            ? userScore.technical_reasoning
                            : 0
                        }}
                      </p>
                    </div>
                  </div>
                </card>
                <div class="new_theme_pattern_content">
                  <div class="main_content_container with_technical_reasoning">
                    <span class="d-block text-uppercase ti">{{
                      localization("TECHNICAL LEARNING PATTERNS")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Solves problems")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Uses few words")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Looks for relevance and practicality")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Works alone")
                    }}</span>
                  </div>
                </div>
              </div>
              <!-- End Technical Reasoning  -->
              <!--Start Confluence  -->
              <div style="min-width: 25%;">
                <card
                  :height="`${userScore.confluence * 2.5 + 120}px`"
                  style="
                    border-radius: 0;

                    min-width: 25%;
                    background-color: #c36;
                    max-height: 230px;
                    min-height: 120px;
                  "
                  class="my-auto score"
                >
                  <div class="d-flex flex-column my-auto">
                    <!-- score title text -->
                    <!-- Medium & larger screens text   -->
                    <p
                      class="mt-1 text-center text-white mb-0"
                      style="font-size: 2rem"
                    >
                      {{ localization("Confluence") }}
                    </p>

                    <div
                      class="
                        d-flex
                        align-items-center align-items-xxl-start
                        justify-content-center justify-content-lg-around
                      "
                    >
                      <!-- First Letter  -->
                      <p class="letter mb-0 text-white">
                        {{ localization("Confluence shortcut") }}
                      </p>
                      <!-- Score Number  -->
                      <!-- Large Screens  -->
                      <!-- Max value 35 - min value 7 = 28 & 25 is defferience between max and min font size & 65 is min font value -->
                      <p
                        class="number mb-0 text-white"
                        :style="{
                          fontSize: (userScore.confluence / 28) * 25 + 65 + 'px'
                        }"
                      >
                        {{
                          userScore.confluence != null
                            ? userScore.confluence
                            : 0
                        }}
                      </p>
                    </div>
                  </div>
                </card>
                <div class="new_theme_pattern_content">
                  <div class="main_content_container with_confluence">
                    <span class="d-block text-uppercase ti">{{
                      localization("CONFLUENCE LEARNING PATTERNS")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Takes risks")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Sees the big picture")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Embraces innovation")
                    }}</span>
                    <span class="d-block te">{{
                      localization("Initiates change")
                    }}</span>
                  </div>
                </div>
              </div>
              <!--End Confluence  -->
            </div>

            <!-- Start New Section  -->
            <div class="wrapper_for_use_first col-sm-12 mt-5 px-0 px-lg-3">
              <div class="main_co">
                <div class="a_one with_title">
                  <span class="text-uppercase">{{
                    localization("DEGREE OF USE")
                  }}</span>
                  <span>:</span>
                </div>
                <div class="a_one ">
                  <span class="text-capitalize">{{
                    localization("Use First")
                  }}</span>
                  <span>:</span>
                  <span class="text-uppercase">35 - 25</span>
                </div>
                <div class="a_one a_two">
                  <span class="text-capitalize">{{
                    localization("As Needed")
                  }}</span>
                  <span>:</span>
                  <span class="text-uppercase">24 - 18</span>
                </div>
                <div class="a_one ">
                  <span class="text-capitalize">{{
                    localization("Avoid")
                  }}</span>
                  <span>:</span>
                  <span class="text-uppercase">17 - 7</span>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
      <!-- // End Large Screens  -->

      <!-- //? end Score Cards  -->
      <div v-loading="$store.getters['user/usersLoad']">
        <div style="width: 100%">
          <card v-if="task">
            <template #header>
              <div
                class="
                  head_of_tasks
                "
                :class="{ is_rtl: isRtl() }"
              >
                <h5 class="mb-2 the_task_name" :class="{ is_rtl: isRtl() }">
                  <i class="fa fa-tasks mr-2"></i>
                  <span>{{ task.name }}</span>

                  <span class="text-muted">( {{ task.topic_name }} )</span>
                </h5>
                <!-- // Edit Task Button && print  -->
                <div class="icons_wrapping_edit_print ">
                  <el-tooltip
                    class=" mb-2  edit-btn print-btn  share_score_button main_tool_tip"
                    content="Edit job"
                    placement="top-start"
                  >
                    <el-button
                      @click="task_dialog = true"
                      type="success"
                      class="mb-2  edit-btn "
                      ><i class="fa fa-edit"></i>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    class=" mb-2 mr-2 ml-2 edit-btn print-btn main_tool_tip"
                    :content="`${localization('Print job')}`"
                    placement="top-start"
                  >
                    <el-button
                      @click="printprofilePage"
                      type="success"
                      class="mb-2 mr-2 ml-2 edit-btn "
                      ><i class="fa fa-print"></i>
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    class=" mb-2 mr-2 ml-2 edit-btn print-btn edit_score_button main_tool_tip"
                    :content="`${localization('Share job')}`"
                    placement="top-start"
                  >
                    <el-button
                      @click="open_share_profile_dialog"
                      type="success"
                      class="mb-2 "
                      ><i class="fa fa-share-alt icons_actions "></i>
                    </el-button>
                  </el-tooltip>
                </div>
              </div>

              <p
                class="pl-2 str_tsk_desc"
                style="
                  background: #aaaaaa;
                  padding: 5px;
                  color: #fff;
                  border-radius: 5px;
                "
                :class="{ 'text-right': isRtl() }"
              >
                {{ localization("Personal strategy description") }}
              </p>
              <!-- Task Description  -->
              <p
                :class="{ 'text-right': isRtl() }"
                class="mt-2 for-task-description"
                style="line-height: 2.1"
                v-html="task.description"
              ></p>
            </template>
            <!-- Start Two Strategy Cards   -->
            <div class="mt-4 mb-4 main_strategy_container">
              <div class="row">
                <div
                  class="col-lg-4 col-md-6 col-sm-12 mb-3"
                  v-if="careerMatchBoxes[0]"
                >
                  <div
                    class="cms_strategy_wrapper"
                    v-html="careerMatchBoxes[0].body"
                  ></div>
                </div>
                <div
                  class="col-lg-4 col-md-6 col-sm-12 mb-3"
                  v-if="careerMatchBoxes[1]"
                >
                  <div
                    class="cms_strategy_wrapper"
                    v-html="careerMatchBoxes[1].body"
                  ></div>
                </div>
                <div
                  class="col-lg-4 col-md-6 col-sm-12 mb-3"
                  v-if="careerMatchBoxes[2]"
                >
                  <div
                    class="cms_strategy_wrapper"
                    v-html="careerMatchBoxes[2].body"
                  ></div>
                </div>
              </div>
            </div>
            <!-- End Two Strategy Cards   -->
            <!-- //! start  collapse  -->

            <el-collapse
              class="mt-2"
              v-model="panel"
              multiple
              @change="open_pattern"
            >
              <div class="child" v-for="(item, i) in task.strategy_id" :key="i">
                <el-collapse-item :name="`${i}`" multiple>
                  <!-- //! start  collapse title -->
                  <template #title>
                    <div
                      class="main_collapse_title_wrapper "
                      style="width: 100%"
                      :class="{ is_rtl: isRtl() }"
                    >
                      <!-- mb-1 mt-2 isRtl() -->
                      <div>
                        <h4 class="every_collapse_title">
                          <i
                            :class="`fa fa-star mr-2 ${i}`"
                            :style="`color: ${getProgressColor(i)}`"
                          ></i>

                          {{ localization(i) }}
                        </h4>
                      </div>
                      <span class="">
                        <button
                          class="btn btn-social btn-round every_collapse_button"
                          :style="
                            `border-color:${getProgressColor(
                              i
                            )};  background: ${getProgressColor(i)}`
                          "
                        >
                          {{ getProgressValue(i) }}
                        </button>
                      </span>
                    </div>
                  </template>
                  <!-- //! end  collapse title -->
                  <div class="p-2 set_bg_white_for_share">
                    <audio controls id="audio" class="d-none">
                      <source id="audio-source" type="audio/mp3" />
                    </audio>
                    <div
                      v-if="item.length > 0"
                      :style="
                        `opacity:0.8;background-color : ${getProgressColor(i)}`
                      "
                      class="mt-2 handler_strategy_style"
                    >
                      <div
                        class="handler_speech_wrapper"
                        v-for="(feedback, i) in item"
                        :key="i"
                      >
                        <div>
                          {{ feedback.strategy }}
                        </div>
                        <i
                          v-tooltip.top-center="
                            `${localization('Read out loud')}`
                          "
                          @click="text_speech_audio(feedback.strategy)"
                          class="fa fa-volume-up icons_style speech"
                          style="cursor: pointer"
                        ></i>
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
                <div
                  class="d-block"
                  style="width: 100%"
                  v-if="
                    !(selected_panel.indexOf(i) >= 0) && showSelectedStrategies
                  "
                >
                  <el-card style="width: 100%">
                    <ul
                      class="mt-2 mx-2 text-dark"
                      style="list-style: none; line-height: 2"
                    >
                      <!-- this is what creates add strategy bug  -->
                      <!-- If show more clicked, the bug disappear  -->
                      <li
                        v-for="(strategy, index) in item"
                        :key="index"
                        v-if="
                          task.selected_startegy_id != null &&
                            task.selected_strategy_id.indexOf(strategy.id) >= 0
                        "
                      >
                        <i
                          :class="`fa fa-star mr-2 ${i}`"
                          :style="`color: ${getProgressColor(i)}`"
                        ></i>
                        {{ strategy.level1 }}
                      </li>
                    </ul>
                  </el-card>
                </div>
                <!-- selected strategies  -->
              </div>
            </el-collapse>
            <!-- //! end  collapse  -->

            <div class="under_words">
              <div class="icons_wrapping_edit_print">
                <el-tooltip
                  class=" mb-2  edit-btn print-btn  share_score_button main_tool_tip"
                  content="Edit job"
                  placement="top-start"
                >
                  <el-button
                    @click="task_dialog = true"
                    type="success"
                    class="mb-2  edit-btn "
                    ><i class="fa fa-edit"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  class=" mb-2 mr-2 ml-2 edit-btn print-btn main_tool_tip"
                  :content="`${localization('Print job')}`"
                  placement="top-start"
                >
                  <el-button
                    @click="printprofilePage"
                    type="success"
                    class="mb-2 mr-2 ml-2 edit-btn "
                    ><i class="fa fa-print"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  class=" mb-2 mr-2 ml-2 edit-btn print-btn edit_score_button main_tool_tip"
                  :content="`${localization('Share job')}`"
                  placement="top-start"
                >
                  <el-button
                    @click="open_share_profile_dialog"
                    type="success"
                    class="mb-2 "
                    ><i class="fa fa-share-alt icons_actions "></i>
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>

    <!--  Add Strategy Dialog  -->
    <el-dialog
      :title="`${localization('Add job strategy')}`"
      :visible.sync="strategy_Dialog"
      top="4vh"
      width="60%"
      class="add_strategy_dialog_task_user"
      :class="{ is_rtl: isRtl() }"
    >
      <el-form
        :model="strategy"
        style="width: 100%"
        :rules="rules"
        ref="addStrategyForm"
        :class="{ form_with_rtl: isRtl() }"
      >
        <el-form-item
          :label="`${localization('Strategy')}`"
          style="width: 100%"
          prop="strategy"
        >
          <el-input
            type="text"
            v-model="strategy.strategy"
            :placeholder="`${localization('Please enter a strategy')}`"
            style="width: 100%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer user_profile_dialog_footer">
        <el-button @click="strategy_Dialog = false">{{
          localization("Cancel")
        }}</el-button>
        <el-button type="primary" @click="add_custom">{{
          localization("Save")
        }}</el-button>
      </span>
    </el-dialog>

    <!--//Edit job Dialog  -->
    <el-dialog
      title="Edit job"
      :visible.sync="task_dialog"
      width="60%"
      top="4vh"
      class="add_strategy_dialog_task_user"
      :class="{ is_rtl: isRtl() }"
    >
      <el-form
        :model="task"
        v-if="task"
        :rules="taskRules"
        ref="taskRef"
        @submit.prevent="save"
        :class="{ form_with_rtl: isRtl() }"
      >
        <el-form-item :label="`${localization('Name')}`" prop="name">
          <el-input
            v-model="task.name"
            autocomplete="off"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="`${localization('Description')}`"
          prop="description"
        >
          <el-input
            v-model="task.desc"
            autocomplete="off"
            type="textarea"
            style="width: 100%"
            class="with_textarea"
          ></el-input>
        </el-form-item>
        <el-form-item :label="`${localization('Topic')}`" prop="topic_id">
          <el-select
            v-model="task.topic_id"
            clearable
            :placeholder="`${localization('Topic')}`"
            style="width: 100%"
          >
            <el-option
              v-for="(item, index) in careerMatchTasksTopics"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="
          dialog-footer
          user_profile_dialog_footer
        "
      >
        <el-button
          @click="task_dialog = false"
          class="text-center text-capitalize"
          >{{ localization("Cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="save"
          class="text-center text-capitalize"
          >{{ localization("Save") }}
        </el-button>
      </div>
    </el-dialog>
    <!--//// Start dialog for share task  -->
    <el-dialog
      :title="`${localization('Share job')}`"
      :visible.sync="share_profile_dialog"
      top="4vh"
      :class="{ share_profile_with_rtl: isRtl() }"
    >
      <el-form
        :model="share_profile_data"
        :rules="share_profile_rules"
        ref="for_add_new_contact"
      >
        <el-form-item
          :label="`${localization('Email')}`"
          prop="email"
          :rules="[
            {
              required: true,
              message: 'Please input email address',
              trigger: 'blur'
            },
            {
              type: 'email',
              message: 'Please input correct email address',
              trigger: ['blur', 'change']
            }
          ]"
        >
          <el-input
            v-model="share_profile_data.email"
            autocomplete="off"
            style="width: 100%"
            :placeholder="`${localization('Email')}`"
            type="email"
          ></el-input>
        </el-form-item>
      </el-form>

      <div
        slot="footer"
        class="
            dialog-footer
            main_dialog_footer
          "
      >
        <el-button
          @click="close_share_profile_dialog"
          class="for_c_buttons text-center text-capitalize"
          >{{ localization("Cancel") }}</el-button
        >
        <el-button
          type="primary"
          class="
              text-center
              for_c_btns
              text-capitalize

            "
          @click="share_profile_page"
          >{{ localization("Send") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- //// End dialog for share task -->
  </div>
</template>

<script>
import LAlert from "src/components/Alert";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
export default {
  name: "index",
  data() {
    return {
      share_profile_dialog: false,
      share_profile_data: {},
      limitationList: 4,
      task_dialog: false,
      strategy_Dialog: false,
      panel: [`Precision`, `Sequence`, "Technical Reasoning", "Confluence"],
      selected_panel: [],
      strategies: [],
      strategy: {},
      showSelectedStrategies: false,
      showMoreStrategies: false,
      patternToshowMore: null,
      rules: {
        level1: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      },
      taskRules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "change"
          }
        ]
      },
      share_profile_rules: {
        email: [
          {
            required: true,
            type: "email",
            message: [
              "Please input email address",
              "Please input correct email address"
            ],
            trigger: ["blur", "change"]
          }
        ]
      },
      careerMatchBoxes: null
    };
  },
  components: {
    LAlert
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    task() {
      return this.$store.getters["user/getShowCareerMatchTaskDetails"];
      // return this.$store.getters["user/onetask"];
    },
    careerMatchTasksTopics() {
      return this.$store.getters["user/getCareerMatchTasksTopics"];
    },
    user() {
      if (this.task) {
        return this.task.user_id ? this.task.user_id : this.auth().id;
      }
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userStrategyBoxes() {
      return this.$store.getters["user/get_user_strategy_boxes"];
    },
    user2: function() {
      return this.$store.state.user.user;
    }
  },
  mounted() {
    if (this.id) {
      this.Refresh();
    }

    // this.$store.dispatch("user/handlerUserStrategyBoxes")
    this.$store.dispatch("user/handlerUserCareerMatchBoxes").then(res => {
      this.careerMatchBoxes = res.career_match;
    });
  },
  methods: {
    //// handling tasks tooltip
    Refresh() {
      this.$store
        .dispatch("user/showCareerMatchTask", { id: this.id })
        .then(_ => {
          this.$store.dispatch("user/user", this.user);
          this.selected_panel = [];
          this.strategies = this.task.selected_strategy_id
            ? this.task.selected_strategy_id
            : [];
          //// handling tasks tooltip
          let html_task_handler = document.querySelector(
            ".for-task-description"
          );
          const all_child_nodes = Array.from(
            html_task_handler.getElementsByTagName("div")
          );
          all_child_nodes.forEach(element => {
            let get_tooltip_data = element.getAttribute("data-tooltip");
            let create_span = document.createElement("span");
            create_span.textContent = get_tooltip_data;
            create_span.setAttribute("class", "span_for_tooltips");
            element.appendChild(create_span);
          });
        });
    },
    printprofilePage() {
      // });
      window.print();
    },
    //// share profile page
    share_profile_page() {
      this.share_profile_dialog = true;
      this.$refs["for_add_new_contact"].validate(valid => {
        if (valid) {
          let cards = document.querySelector(".wrapper_for_sharing_tasks");
          html2canvas(cards, {
            logging: true,
            letterRendering: 1,
            allowTaint: true,
            useCORS: true
          }).then(canvas => {
            const payload = new FormData();
            canvas.toBlob(blob => {
              let img_to_blob = new File([blob], "profile.png", {
                type: "image/png"
              });
              payload.append("photo", img_to_blob);
              payload.append("email", this.share_profile_data.email);
              this.$store.dispatch("user/sharing_profile_task", payload);
              Swal.fire({
                title: "",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                icon: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              });
              this.share_profile_dialog = false;
              this.share_profile_data.email = "";
            }, "image/png");
          });
        }
      });
    },
    close_share_profile_dialog() {
      this.share_profile_dialog = false;
      this.share_profile_data.email = "";
    },
    open_share_profile_dialog() {
      this.share_profile_dialog = true;
    },
    showLessStrategies(arr) {
      let newArr = [...arr];

      return newArr;
    },
    open_pattern(val) {
      this.selected_panel = val;
      this.showSelectedStrategies = true;
    },
    toggleShownStrategiesNumber(i) {
      // change shown strategies number
      this.showMoreStrategies = !this.showMoreStrategies;
      // this is to only show more strategies of the selected pattern not all patterns
      this.patternToshowMore = i;
    },
    setShowSelectedStrategies(i) {
      let status = false;
      if (
        this.selected_panel.length > 0 &&
        this.selected_panel.indexOf(i) > 0
      ) {
        status = true;
      }
      console.log(status);
      return status;
    },
    pickSelectedStrategies(arr, selectedStrategies) {
      let selectedArr = [
        ...arr.filter(item =>
          selectedStrategies ? selectedStrategies.indexOf(item.id) > 0 : item
        )
      ];
      return selectedArr;
    },
    //// handler patterns
    getHint(key, decode, score, item) {
      var type = false;

      if (score >= 7 && score < 18) {
        type = "Forge";
      } else if (score >= 18 && score < 25) {
        type = "Intensify";
      }
      if (decode) {
        type = item[0] ? item[0].classification : type;
        if (type == "Use First") {
          if (key == "Sequence") {
            return `${this.localization("Sequence - Use First")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision - Use First")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR - Use First")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence - Use First")}`;
          }
        } else if (type) {
          // console.log(score);
          console.log(type);
          // Intensify
          if (type == "Intensify" && key == "Sequence") {
            return `${this.localization("Sequence Intensify")}`;
          } else if (type == "Intensify" && key == "Precision") {
            return `${this.localization("Precision Intensify")}`;
          } else if (type == "Intensify" && key == "Technical Reasoning") {
            return `${this.localization("TR Intensify")}`;
          } else if (type == "Intensify" && key == "Confluence") {
            return `${this.localization("Confluence Intensify")}`;
          }
          if (type == "Forge" && key == "Sequence") {
            return `${this.localization("Sequence Forge")}`;
          } else if (type == "Forge" && key == "Precision") {
            return `${this.localization("Precision Forge")}`;
          } else if (type == "Forge" && key == "Technical Reasoning") {
            return `${this.localization("TR Forge")}`;
          } else if (type == "Forge" && key == "Confluence") {
            return `${this.localization("Confluence Forge")}`;
          }
        } else {
          //// if pattern exist and score between 25 &b 35
          if (key == "Sequence") {
            return `${this.localization("Sequence - Use First")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision - Use First")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR - Use First")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence - Use First")}`;
          }
        }
      } else {
        // If no pattern //// and score between 7-18 -- type now FORGE
        if (type && type == "Forge") {
          if (key == "Sequence") {
            return `${this.localization("Sequence No Term")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision No Term")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR No Term")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence No Term")}`;
          }
        } else if (type && type == "Intensify") {
          if (key == "Sequence") {
            return `${this.localization("Sequence Intensify")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision Intensify")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR Intensify")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence Intensify")}`;
          }
        } else {
          if (key == "Sequence") {
            return `${this.localization("Sequence tether")}`;
          } else if (key == "Precision") {
            return `${this.localization("Precision tether")}`;
          } else if (key == "Technical Reasoning") {
            return `${this.localization("TR tether")}`;
          } else if (key == "Confluence") {
            return `${this.localization("Confluence tether")}`;
          }
        }
      }
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    getProgressValue(key) {
      switch (key) {
        case "Precision":
          return this.userScore.precision;
        case "Technical Reasoning":
          return this.userScore.technical_reasoning;
        case "Confluence":
          return this.userScore.confluence;
        case "Sequence":
          return this.userScore.sequence;
      }
    },
    select_strategies() {
      this.task.selected_strategy_id = this.strategies;
      this.$store.dispatch("user/updateCareerMatchTask", {
        id: `${this.id}`,
        query: { selected_strategy_id: this.strategies }
      });
    },
    open_strategy_dialog(pattern) {
      this.strategy.pattern = pattern;
      this.strategy_Dialog = true;
    },
    add_custom() {
      this.$refs["addStrategyForm"].validate(valid => {
        // if all required fields filled with valide values
        if (valid) {
          // console.log(this.strategy.level1);
          this.$store
            .dispatch("strategies/handlerAddCustomCareerStrategy", {
              id: `${this.id}`,
              query: this.strategy
            })
            .then(_ => {
              this.strategy_Dialog = false;
              this.strategy.strategy = "";
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
              this.Refresh();
            });
        } else {
          // if not all required fields filled with valide values
          console.log("error submit!!");
          return false;
        }
      });
    },
    save() {
      this.$refs["taskRef"].validate(valid => {
        // if all required fields filled with valide values
        if (valid) {
          // if add new task
          // if edit an existing task
          this.task.desc = this.task.desc.replace(/\s/g, "_");

          this.$store
            .dispatch("user/handleEditCareerMatchTask", {
              query: {
                name: this.task.name,
                description: this.task.desc,
                topic_id: this.task.topic_id
              },
              id: this.task.id
            })
            .then(_ => {
              this.task_dialog = false;
              Swal.fire({
                title: "",
                icon: "success",
                text: `${this.localization("Done")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
              this.Refresh();
            })
            .catch(_ => {
              this.task_dialog = false;
            });
        } else {
          // if not all required fields filled with valide values
          console.log("error submit!!");
          return false;
        }
      });
    },
    // // text_speech_audio
    text_speech_audio(any_text) {
      this.$store
        .dispatch("user/get_text_to_speech_audio", { text: any_text })
        .then(res => {
          let audioBlob = new Blob([res], { type: "audio/mpeg" });
          let audioURL = URL.createObjectURL(audioBlob);
          let audio = document.getElementById("audio");
          var source = document.getElementById("audio-source");
          source.src = audioURL;
          audio.load();
          audio.play();
        });
    }
  }
};
</script>

<style scoped lang="scss">
.handler_speech_wrapper {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  .icons_style {
    cursor: pointer;
  }
}
.handler_strategy_style {
  font-size: 1.2rem;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px 15px;
  line-height: 1.7;

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
/* mobile score progress design */
.print-btn {
  background-color: #39c;
  border-color: #39c;
  transition: 0.2s;
  &.item {
    background-color: #f93;
    background-color: #c36;
    padding: 12px 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    color: #fff !important;
    // .link-extention {
    // }
  }
  &:hover {
    opacity: 0.8;
  }
  &.edit_score_button {
    background-color: #3c3;
    border-color: #3c3;
  }
  &.share_score_button {
    background-color: #f93;
    border-color: #f93;
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.el-collapse {
  background: #fff;
}
.set_bg_white_for_share {
  background: #fff;
}

::v-deep .precision {
  /* margin: 5px; */
  display: inline-block;
  color: #3c3;
  // text-decoration: underline;
  margin: 0;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #3c3;
  }
  .span_for_tooltips {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    bottom: 100%;
    background-color: #333;
    color: #fff;
    min-width: 70px;
    text-align: center;
    display: none;
    transition: 0.5s;
    font-size: 16px;
    border-radius: 5px;
    z-index: 555;
    &:hover {
      display: none;
      pointer-events: none;
    }
    &::before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      border-style: solid;
      border-width: 10px;
      border-color: #333 transparent transparent transparent;
    }
  }
  &:hover {
    .span_for_tooltips {
      display: block;
    }
  }
}

::v-deep .technical_reasoning {
  display: inline-block;
  color: #f93;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #f93;
  }
  .span_for_tooltips {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    bottom: 100%;
    background-color: #333;
    color: #fff;
    min-width: 70px;
    text-align: center;
    display: none;
    transition: 0.5s;
    font-size: 16px;
    border-radius: 5px;
    z-index: 555;
    &:hover {
      display: none;
      pointer-events: none;
    }
    &::before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      border-style: solid;
      border-width: 10px;
      border-color: #333 transparent transparent transparent;
    }
  }
  &:hover {
    .span_for_tooltips {
      display: block;
    }
  }
}

::v-deep .sequence {
  display: inline-block;
  color: #39c;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  transition: 0.5s;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #39c;
  }
  &::after {
    position: absolute;
    // content: attr(data-tooltip);
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    bottom: 85%;
    background-color: #000;
    color: #fff;
    min-width: 70px;
    text-align: center;
    display: none;
    transition: 0.5s;
    font-size: 16px;
    border-radius: 5px;
  }
  .span_for_tooltips {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    bottom: 100%;
    background-color: #333;
    color: #fff;
    min-width: 70px;
    text-align: center;
    display: none;
    transition: 0.5s;
    font-size: 16px;
    border-radius: 5px;
    z-index: 555;
    &:hover {
      display: none;
      pointer-events: none;
    }
    &::before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      border-style: solid;
      border-width: 10px;
      border-color: #333 transparent transparent transparent;
    }
  }
  &:hover {
    .span_for_tooltips {
      display: block;
    }
  }
}

::v-deep .el-textarea__inner {
  height: 150px !important;
  line-height: 1.7 !important;
  padding: 10px 15px;
  resize: none;
  word-break: break-word;
}

::v-deep .confluence {
  display: inline-block;
  color: #c36;
  margin: 0;
  // padding: 3px;
  padding: 0 0.3rem;
  font-weight: bold;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 1.5px;
    top: 100%;
    background-color: #c36;
  }
  .span_for_tooltips {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    bottom: 100%;
    background-color: #333;
    color: #fff;
    min-width: 70px;
    text-align: center;
    display: none;
    transition: 0.5s;
    font-size: 16px;
    border-radius: 5px;
    z-index: 555;
    &:hover {
      display: none;
      pointer-events: none;
    }
    &::before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      border-style: solid;
      border-width: 10px;
      border-color: #333 transparent transparent transparent;
    }
  }
  &:hover {
    .span_for_tooltips {
      display: block;
    }
  }
}

::v-deep .el-checkbox {
  text-transform: capitalize !important;
  display: flex;
  &.check_box_with_rtl {
    flex-direction: row-reverse;
    margin-right: 0;
    align-items: center;
    gap: 3px;
  }
  // check_box_with_rtlflex-row-reverse  mr-0
  // background-color: #eee;
}
// #23CCEF #FB404B
::v-deep .el-checkbox__label {
  word-break: break-word !important;
  white-space: pre-wrap;
  // word-wrap: break-word;
  white-space: normal;
  color: #000;
  padding: 0 7px;
  text-transform: initial;
}
::v-deep .el-checkbox__inner {
  border: 1px solid #000;
}
::v-deep .texst {
  word-break: break-word !important;
  word-wrap: break-word;
  white-space: normal;
  // white-space: pre-wrap;
  text-transform: initial;
  font-size: 1.2rem;
  line-height: 1;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
.alert_for_collapse {
  font-size: 1.2rem;
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
.label {
  border-radius: 50%;
  margin: 0.5rem;
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 2.8;
  color: white;
}

// while printing
// the_patterns_with_new_theme
.main_use_ur_pattern_container_lg {
  display: none;
  @media (min-width: 991px) {
    display: block;
  }
  @media print {
    display: block !important;
  }
}
.hidden_when_print_sm_screen {
  // @media print {
  //   display: none !important;
  // }
  display: none;
  @media (min-width: 320px) and (max-width: 991px) {
    display: block;
  }
  @media print {
    display: none !important;
  }
}
.the_patterns_with_new_theme {
  position: relative;
  .new_theme_pattern_content {
    display: none;
    @media print {
      display: block !important;
    }
    padding: 0 10px;
    .main_content_container {
      border-left: 2px solid;
      border-right: 2px solid;
      border-bottom: 2px solid;
      text-align: center;
      padding: 10px 5px;
      &.with_sequence {
        border-color: #39c;
      }
      &.with_precision {
        border-color: #3c3;
      }
      &.with_technical_reasoning {
        border-color: #f93;
      }
      &.with_confluence {
        border-color: #c36;
      }
      .ti {
        // color: #555;
        font-size: 15px;
      }
      .te {
        color: #777;
        font-size: 13px;
      }
    }
  }
}
.wrapper_for_use_first {
  display: none;
  @media print {
    display: block !important;
  }
  .main_co {
    background-color: #eee;
    display: flex;
    justify-content: center;
    gap: 10px;
    color: #7d7979;
    padding: 15px;
    .a_one {
      &.with_title {
        color: #555;
      }
    }
    .a_two {
      border-left: 3px solid #777;
      border-right: 3px solid #777;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.main_auth_user_info {
  text-align: center;
  display: none;
  @media print {
    display: block !important;
  }
  .n_name {
    font-size: 24px;
    font-weight: bold;
    text-transform: capitalize;
  }
  .n_email {
    font-size: 22px;
  }
  .classification_classification {
    padding: 0 36px;
    min-width: 180px;
    border-radius: 12px;
    background-color: #00563f;
    height: 45px;
    display: flex;
    align-items: center;
    width: fit-content;
    color: #fff;
    margin: 10px auto;
  }
}
.another_content {
  // text-align: center;
  color: #555;
  .tit {
    font-size: 22px;
    margin-bottom: 10px;
    display: block;
  }
  .text {
    line-height: 1.7;
  }
  display: none;
  @media print {
    display: block !important;
  }
}

// while printing

// Fixed Bug In Background Color In Medium And Small Screens
::v-deep .sequence {
  &.main-label {
    color: #fff;
    background-color: #39c;
    &::before {
      opacity: 0;
    }
  }
}
::v-deep .confluence {
  &.main-label {
    color: #fff;
    background-color: #c36;
    &::before {
      opacity: 0;
    }
  }
}
::v-deep .technical_reasoning {
  &.main-label {
    color: #fff;
    background-color: #f93;
    &::before {
      opacity: 0;
    }
  }
}
::v-deep .precision {
  &.main-label {
    background-color: #3c3;
    color: #fff;
    &::before {
      opacity: 0;
    }
  }
}

.letter {
  font-size: 40px;
}

.name {
  font-weight: 300;
  font-size: 19px;
  line-height: 1.2;
  padding-left: 0;
  color: white;
}

.border {
  border: 2px solid white !important;
}

.number {
  line-height: 1;
}

.row {
  margin-top: 0;
}

.v-expansion-panel--active > button {
  background-color: #757575 !important;
  color: #fff;
}

.v-expansion-panel--active > .theme--light .v-icon {
  color: #fff !important;
}

.child.v-expansion-panel--active > button {
  background-color: #26a1da !important;
  color: #fff;
}

.v-slider--horizontal .v-slider__track-container {
  height: 10px !important;
}

.for-task-description,
.str_tsk_desc,
.the_task_name {
  font-size: 1.2rem;
  &.is_rtl {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    align-items: center;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 1rem;
  }
}
//// new  sequence
.for-task-description {
  .span_for_tooltips {
    background: #000;
    display: block;
  }
}
// for the empty div if the ocordion is collapse
::v-deep .el-card__body {
  display: none;
}

.main_collapse_title_wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px;

  .every_collapse_title {
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: 18px;
    }
    @media (min-width: 320px) and (max-width: 350px) {
      font-size: 11px;
    }
  }
  .every_collapse_button {
    color: #fff;
    width: 50px;
    height: 50px;
    @media (min-width: 320px) and (max-width: 480px) {
      width: 36px;
      height: 36px;
    }
  }
  &.is_rtl {
    flex-direction: row-reverse;
    padding-right: 10px;
  }
}
.user_profile_dialog_footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  @media (min-width: 320px) and (max-width: 767px) {
    justify-content: center;
  }
}
.head_of_tasks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  &.is_rtl {
    flex-direction: row-reverse;
  }

  .icons_wrapping_edit_print {
    display: flex;
    align-items: center;
    gap: 20px;
    @media print {
      display: none;
    }
    .print_task_button {
      // background-color: #fff;
      color: #fff;
      border: none;
      box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s all ease-in-out;
      background-color: #11bfe3;
      .icons_actions {
        font-size: 14px;
      }

      &.edit_task_button {
        background-color: #ffa534;
      }
      &.share {
        background-color: #3c3;
      }
    }
  }
}
.under_words {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: end;
  padding-top: 10px;
  &.is_rtl {
    flex-direction: row-reverse;
  }
  .icons_wrapping_edit_print {
    display: flex;
    align-items: center;
    gap: 20px;
    @media print {
      display: none;
    }
    .print_task_button {
      // background-color: #fff;
      color: #fff;
      border: none;
      box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s all ease-in-out;
      background-color: #11bfe3;
      .icons_actions {
        font-size: 14px;
      }

      &.edit_task_button {
        background-color: #ffa534;
      }
      &.share {
        background-color: #3c3;
      }
    }
  }
}
.main_strategy_container {
  .cms_strategy_wrapper {
    padding: 8px;
    // border: 1px solid rgba(#ccc, 0.3);
    color: #fff;
    border-radius: 10px;
    background-color: #00563f;
    min-height: 140px;
  }
}
</style>

<style lang="scss">
.share_profile_with_rtl {
  text-align: right;
  .el-form-item__label {
    width: 100%;
  }
}
.for_tasks_editing_page {
  .el-collapse-item__header {
    padding: 2.5rem 0;
  }
}

.add_strategy_dialog_task_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
  &.is_rtl {
    .el-dialog__header {
      text-align: right !important;
    }
  }
  .form_with_rtl {
    .el-form-item__label {
      width: 100%;
      text-align: right;
    }
    .el-input__inner,
    .el-textarea__inner {
      text-align: right;
    }
  }
}

//? tooltip style
.el-tooltip__popper.is-dark {
  // background-color: #fff !important;
  // color: #707070;
  // border-radius: 5px;
  // box-shadow: 0 1px 12px rgba(0, 0, 0, 0.2);
  // .popper__arrow {
  //   border-top-color: #fff;
  //   &::after {
  //     border-top-color: #fff;
  //   }
  // }
  @media print {
    display: none !important;
  }
}
</style>
